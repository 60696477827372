<template>
  <section v-if="user.identifiant == 'PESNOT-PIN'" class="log-viewer">
    <div class="titre">
      Log Viewer v1.0
      <div class="refresh" @click="loadLogs">🔄</div>
    </div>
    <select v-model="logToView">
      <option
        v-for="log of logListFiltered"
        :key="log.id_log"
        :value="log"
        :class="successOrFailClass(log)"
        >{{
          `${log.id_log} - ${
            log.success_log == true ? "Succes" : "Echec"
          } - ${prettyDate(log.date_log)} - ${log.type_log.slice(0, 20)} - ${
            log.identifiant_log
          }`
        }}
      </option>
    </select>
    <div class="ligne-filtres">
      <div>
        <select class="select-contributeur" v-model="indexContributeur">
          <option value="-">Tous les contributeurs</option>
          <option
            v-for="(contributeur, index) of listeContributeurs"
            :value="index"
            :key="contributeur.id_contributeur"
            >{{
              `${contributeur.nom_contributeur} ${contributeur.prenom_contributeur}`
            }}</option
          >
        </select>
        <span class="resetButton" @click="dateReset">❌</span>
      </div>
      <div class="filter-container">
        <ComposantCheckbox v-model="prendreDateEnCompte" label="" />
        <input type="date" v-model="logDate" />
        <span class="resetButton" @click="dateReset">❌</span>
      </div>
      <div class="filter-container">
        <ComposantCheckbox v-model="prendreHeureEnCompte" label="" />
        <input
          class="input-heure"
          type="number"
          min="0"
          max="24"
          v-model="heures"
        />
        h
        <input
          class="input-heure"
          type="number"
          min="0"
          max="59"
          v-model="minutes"
        />min
      </div>
      <div class="filter-container">
        <select name="" id="" v-model="statutLog">
          <option value="tous">Tous</option>
          <option :value="true">Succes</option>
          <option :value="false">Echec</option>
          <option value="warning">Warning</option>
        </select>
      </div>
    </div>
    <div class="ligne-filtres">
      <div class="filter-container">
        <label for="recherche">Recherche :</label>
        <input type="text" v-model="clesRecherche" /><span
          class="resetButton"
          @click="rechercheReset"
          >❌</span
        >
      </div>
    </div>
    <div class="container">
      <div class="details" :class="successOrFailClass(logToView)">
        <div class="resume-log">
          <h6>Identification</h6>
          Id: {{ logToView.id_log }} <br />
          Date: {{ prettyDate(logToView.date_log) }} <br />
          Contributeur: {{ logToView.identifiant_log }}
          <div class="type-log">
            <h6>Type</h6>
            {{ logToView.type_log }}
          </div>
        </div>
        <h6>Message</h6>
        <div class="message-log" v-html="logToView.message_log">
          <!-- Message : {{  }} -->
        </div>
      </div>
      <div class="details">
        Détails :
        <json-viewer
          class="json-viewer"
          :value="originalMessageParsed"
          copyable
          boxed
        ></json-viewer>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { consoleMessage } from "../../js/consoleMessage";
export default {
  name: "LogViewer",
  data: () => {
    return {
      switchStatus: false,
      prendreHeureEnCompte: false,
      prendreDateEnCompte: false,
      heures: 0,
      minutes: 0,
      clesRecherche: "",
      statutLog: "tous",
      logList: [
        {
          id_log: 0,
          type_log: "aucun log choisi",
          message_original_log: "",
          identifiant_log: "",
        },
      ],
      logToView: {
        id_log: 0,
        type_log: "aucun log choisi",
        message_original_log: [],
      },
      indexContributeur: "-",
      logDate: "",
      logTime: "",
    };
  },
  computed: {
    ...mapState(["user", "listeContributeurs"]),
    originalMessageParsed() {
      try {
        return JSON.parse(this.logToView.message_original_log);
      } catch (err) {
        return this.logToView.message_original_log;
      }
    },
    contributeur() {
      if (this.listeContributeurs == undefined || this.indexContributeur == "-")
        return { identifiant_contributeur: 0 };
      return this.listeContributeurs[this.indexContributeur];
    },
    chosenDateTime() {
      return new Date(
        this.logDate +
          (this.prendreHeureEnCompte
            ? " " + this.heures + ":" + this.minutes
            : "")
      );
    },
    logListFiltered() {
      return this.logList.filter((log) => {
        return (
          (log.id_contributeur == this.contributeur.id_contributeur ||
            this.indexContributeur == "-") &&
          this.dateLogDansInterval(new Date(log.date_log), 1) &&
          this.rechercheLog(log) &&
          this.matchStatus(log)
        );
      });
    },
  },
  methods: {
    ...mapActions(["phpPost"]),
    matchStatus(log) {
      switch (this.statutLog) {
        case "tous":
          return true;
        case true:
          return log.success_log == "1";
        case false:
          return log.success_log == "0";
        case "warning":
          return log.message_original_log.warning;
      }
    },
    rechercheLog(log) {
      return (
        this.clesRecherche == "" ||
        log.identifiant_log
          .toLowerCase()
          .includes(this.clesRecherche.toLocaleLowerCase()) ||
        log.message_original_log
          .toLowerCase()
          .includes(this.clesRecherche.toLocaleLowerCase()) ||
        log.message_log
          .toLowerCase()
          .includes(this.clesRecherche.toLocaleLowerCase()) ||
        log.type_log
          .toLowerCase()
          .includes(this.clesRecherche.toLocaleLowerCase())
      );
    },
    rechercheReset() {
      this.clesRecherche = "";
    },
    dateLogDansInterval(dateTimeLog, interval) {
      if (!this.prendreDateEnCompte) return true;
      let referenceDateTimeStart = new Date(this.chosenDateTime);
      let referenceDateTimeEnd = new Date(this.chosenDateTime);
      if (this.prendreHeureEnCompte) {
        referenceDateTimeStart.addHours(-interval);
        referenceDateTimeEnd.addHours(interval);
      } else {
        // referenceDateTimeStart.addDays(interval);
        referenceDateTimeEnd.addDays(interval);
      }

      if (referenceDateTimeStart.toString() == "Invalid Date") return true;
      if (dateTimeLog.toString() == "Invalid Date") return true;
      return (
        dateTimeLog >= referenceDateTimeStart &&
        dateTimeLog <= referenceDateTimeEnd
      );
    },
    timeReset() {
      this.logTime = "";
    },
    dateReset() {
      this.logDate = "";
    },
    prettyDate(dateAndTime) {
      if (dateAndTime == undefined) return;
      dateAndTime = dateAndTime.split(" ");
      let date = dateAndTime[0].split("-");
      date.reverse();
      return date.join("/") + " " + dateAndTime[1];
    },
    successOrFailClass(log) {
      if (log == undefined) return "";
      if (log.success_log == true) {
        if (log.message_original_log.warning) return "warning";
        return "succes";
      }
      return "echec";
    },
    loadLogs() {
      this.phpPost({
        phpFile: "assets/php/getLogs.php",
        disableThumbnailForAll: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.logList = response.reqList;
          } else console.error(response);
        },
      });
    },
  },
  mounted: function() {
    this.loadLogs();
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/css/components.scss";
.resetButton {
  cursor: pointer;
}
.succes {
  background-color: rgb(191, 255, 175);
}

.echec {
  background-color: rgb(255, 168, 141);
}

.warning {
  background-color: rgb(253, 230, 99);
}

.log-viewer {
  // margin-top: 50px;
  margin-bottom: 50px;
}
.titre {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.refresh {
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.select-contributeur {
  width: 200px;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  & input {
    width: 150px;
    &.input-heure {
      width: 40px;
    }
  }
}
.ligne-filtres {
  display: flex;
  margin-top: 10px;
  & select {
    height: 100%;
  }
}

section {
  width: 100%;
  // border: 5px solid black;
}

.type-log {
  // text-align: center;
}

.message-log {
}

.liste-logs {
  margin: 20px 0;
  @media (max-width: $mobile-break-point) {
    width: 300px;
  }
}

.details {
}

.json-viewer {
  @media (max-width: $mobile-break-point) {
    width: 100%;
    overflow: auto;
  }
}
</style>
